.backdropContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.actionDropdownButton {
  width: 32px !important;
  height: 32px !important;
}

.actionDropdownMenu {
  width: 265px !important;
  top: 40px !important;
}

.backdrop {
  flex: 1;
  height: 100%;
}

.body {
  padding: 12px 24px 0 24px;
  background: #f3f4f5;
  overflow-y: auto;
}

.container {
  width: 600px;
  height: 100vh;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  margin: 0 0 0 auto;
  overflow: hidden;
  border-radius: 12px 0 0 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.nav {
  height: 64px;
  min-height: 64px;
  top: 0;
  width: 100%;
  margin: 0 auto 0 0;
  background: var(--kom-c-secondary-500);
  background: linear-gradient(90deg, #1c406f, #215769);
  position: sticky;
  border-radius: 12px 0 0 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .container {
    height: 100vh;
    border-radius: 0 0 0 0;
  }

  .nav {
    border-radius: 0;
  }
}

.container :global(.page-title .inner) {
  padding: 35px 24px;
}

.container :global(.page-title .inner .back-button) {
  margin-left: 0;
}

.content {
  padding: 64px 24px 24px;
}

.header {
  display: flex;
  padding: 24px 24px 0 24px;
  height: 95px;
  min-height: 95px;
  border-bottom: solid 1px #ddd;
  position: sticky;
  top: 64px;
  background: #fff;
  z-index: 1;
}

.overview {
  display: flex;
}

.coverContainer img {
  width: 50px;
  height: auto;
  border-radius: 8px;
}

.overviewText {
  flex: 1;
  padding: 0 0 0 24px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  color: #1d406f;
}

.details {
  display: flex;
  margin-top: 24px;
}

.detailsElement {
  margin-right: 24px;
}

.details .detailsElement:first-child {
  min-width: 160px;
  width: 160px;
}

.detailsElement h5 {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.4;
}

.detailsElement p {
  margin: 0;
  display: flex;
}

.detailsElement svg {
  width: 16px;
  margin-right: 4px;
  color: #008275;
}

.fileContainer {
  margin-top: 24px;
}

.fileContainer label {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.8;
  display: block;
  margin-bottom: 4px;
}

.fileContentContainer {
  background: #fff;
  display: flex;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.fileLink {
  height: 64px;
  padding: 0 0 0 16px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.fileActions {
  display: flex;
  margin: 8px 8px 8px 0;
}

.fileActions>a {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: #174944;
  cursor: pointer;
}

.fileActions>a:hover {
  color: #008275;
  background: rgba(0, 130, 117, 0.1)
}

.fileActions>a>svg {
  margin: 0 auto;
}

.available {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgb(136, 196, 0);
  padding: 4px;
  color: #fff;
  margin-right: 16px;
}

.fileActions>.delete:hover {
  color: #d9273d !important;
  background: rgba(217, 39, 61, 0.1) !important;
}

.link {
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link:hover {
  text-decoration: underline;
}

.unpublished {
  background: rgba(28, 64, 111, 0.1);
  color: #1c406f;
  padding: 0 4px;
  border-radius: 2px;
  height: 18px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.profileImage {
  height: 52px;
  width: 52px;
}

.username {
  font-weight: bold;
  font-size: 18px;
  color: #1d406f;
}

.ssoId>span {
  margin-right: 4px;
}

.section {
  background: #fff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.sectionBody {
  padding: 0;
}

.sectionHeader {
  padding: 0 16px 16px 0;
  font-weight: bold;
}

.element {
  display: flex;
  margin-bottom: 12px;
}

.elementLabel {
  display: flex;
  flex: 1;
  pointer-events: none;
}

.elementLabelContent {
  padding-right: 16px;
}

.elementLabelSeparator {
  border-bottom: dotted 1px #aaa;
  flex: 1;
  margin-bottom: 4px;
}

.elementValue {
  margin-left: 16px;
}

.elementValue.success {
  background: rgba(6, 190, 6, 0.21);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
  color: rgb(3, 121, 3);
}

.elementValue.warning {
  background: rgba(255, 165, 0, 0.19);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
  color: rgb(201, 131, 3)
}

.elementValue.warning :global(.mdi-icon) {
  color: orange;
}

.elementValue.success :global(.mdi-icon) {
  color: green;
}

.elementValue :global(.mdi-icon) {
  width: 16px;
  margin-right: 8px;
}

.checkYes {
  color: green;
}

.checkWarning {
  color: orange;
}

.daysInputContainer {
  position: relative;
  width: 170px;
}

.daysInputContainer :global(.input-field) {
  margin: 0;
}

.daysInputLabel {
  position: absolute;
  right: 0;
  top: 12px;
  color: #aaa;
  user-select: none;
}

.daysUpdateContainer {
  display: flex;
}

.copyable {
  cursor: pointer;
  padding: 0 4px;
  margin: 0 -4px;
  border-radius: 4px;
  transition: background-color 0.1s;
}

.copyable:hover {
  background-color: #eee;
}

.tab {
  padding: 0 !important;
}

.body :global(.row) {
  margin: 0;
}

:global(input[type=text]).inputField {
  margin: 0;
  padding: 12px;
  height: auto;
  width: 155px;
  border: solid 2px #ddd;
  background: #fff;
  border-radius: 4px;
  transition: box-shadow .15s, border .15s;
}

:global(input[type=text]).inputField:hover {
  border-color: #aaa;
}

:global(input[type=text]).inputField:focus {
  border: solid 2px #26a69a !important;
  box-shadow: none !important;
}

.submitButton {
  border-radius: 4px;
  height: 46px;
  margin-left: 24px;
}

.proDaysInfo {
  font-size: 12px;
}

.closeButton {
  opacity: 1;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #b5bac3;
  cursor: pointer;
  transition: color 0.1s;
  user-select: none;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.closeButton :global(svg) {
  margin: 0 auto;
}

.closeButton:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.overlayTitle {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 16px;
}

.containerBackground {
  position: fixed;
  right: 0;
  width: 600px;
  background: #f3f4f5;
  height: calc(100% - 50px);
  top: 50px;
}

.renewalCheckbox :global([type="checkbox"]+span:not(.lever)) {
  height: 20px;
  line-height: 20px;
}

.renewalCheckbox :global([type="checkbox"]+span) {
  color: #374e59;
}

.renewalCheckbox :global([type="checkbox"].filled-in:checked+span) {
  font-weight: 500;
}


.renewalInfo {
  margin-top: 16px;
}
.renewalInfo label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}

.renewalInfo textarea {
  margin: 0;
  padding: 12px;
  height: auto;
  width: 100%;
  border: solid 2px #ddd;
  background: #fff;
  border-radius: 4px;
  transition: box-shadow .15s, border .15s;
}

.renewalInfo textarea:hover {
  border-color: #aaa;
}

.renewalInfo textarea:focus,
.renewalInfo textarea:focus-visible {
  border: solid 2px #26a69a !important;
  box-shadow: none !important;
  outline: none;
}

.renewalActions {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.editButton {
  border-radius: 4px;
  border: none;
  height: auto;
  width: auto;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  user-select: none;
  background-color: #fff;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
  color: #7d8897;
  height: 32px;
  padding: 0px 8px 0px 6px;
  transition: background-color 0.15s, border 0.15s, box-shadow 0.15s;
}

.editButton:hover,
.editButton:focus {
  background-color: #f3f8ff;
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
}