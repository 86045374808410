.badges {
  display: flex;
  align-items: center;
}

.badges.withSeparator {
  margin-right: 24px;
}

.badge {
  color: #fff;
  font-size: 12px;
  margin-right: 8px;
  border-radius: 4px;
  height: 28px;
  padding: 0 6px 0 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.badge :global(.mdi-icon) {
  width: 16px;
  margin-right: 4px;
}

.badge:last-child {
  margin-right: 0px;
}

.badge.success {
  color: green;
  border: solid 1px rgb(178 208 178);
  background: rgb(202 241 202);
}

.badge.info {
  color: #6e8791;
  background: #f4f6f7;
  border: solid 1px #d6d5d5;
}

.badge.warning {
  color: #ed9900;
  border: solid 1px rgb(245 216 163);
  background: rgb(255 244 223);
  font-weight: 500;
}

.badges.withSeparator .badge:last-child:after {
  content: '';
  position: absolute;
  right: -13px;
  top: 3px;
  height: 20px;
  width: 1px;
  background: #d6d5d5;
}