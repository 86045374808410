.container {
  display: flex;
  flex-direction: column;
  height: auto;
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  margin-bottom: 16px;
  position: relative;
}

.inputContainer :global(.mdi-icon).searchIcon {
  position: absolute;
  left: 8px;
  top: 9px;
  width: 24px;
  height: 24px;
  color: rgb(158, 158, 158);
  transition: color 0.15s;
}

:global(.preloader-wrapper.active.small).spinner {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 12px;
  right: 12px;
}

:global(.preloader-wrapper.active.small).spinner .circle {
  border-width: 2px;
}

.inputLabel {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  color: rgb(108, 112, 116);
}

.inputContainer input {
  margin: 0;
  padding: 8px 38px 8px 38px;
  height: auto;
  width: 100%;
  background: #fff;
  font-weight: 500;
  border-radius: 8px;
  transition: box-shadow .15s, border .15s;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
}

.inputContainer input:hover {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
  background: #fff !important;
}

.inputContainer input:focus {
  border: solid 1px #26a69a !important;
  background: #fff !important;
  box-shadow: 0 1px 4px #00000017 !important;
}

.inputContainer input.disabledInput {
  pointer-events: none;
  cursor: default;
  background: #f2f0f0;
}

.refetchButton {
  position: absolute;
  height: 32px;
  top: 5px;
  right: 5px;
  width: 32px;
  border-radius: 4px;
  color: rgb(158, 158, 158);
  transition: color 0.15s;
}

.refetchButton :global(.mdi-icon) {
  margin: 0 auto;
}

.refetchButton:hover, .refetchButton:focus {
  background: #ccece9 !important;
  color: #008275;
}

.refetchButton:active {
  background: #bee4e0 !important;
  color: #008275;
}


.list {
  border-radius: 8px;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
  height: 328px;
  background: #fff;
}

@media screen and (max-height: 816px) {
  .list {
    height: 286px;
  }
}

@media screen and (max-height: 684px) {
  .list {
    height: 281px;
  }
}

.list:hover {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
}

.list.disabledList {
  pointer-events: none;
  cursor: default;
  background: #f2f0f0;
}

.listItem {
  padding: 12px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listItem:hover {
  background: #f3f4f5;
}

.listItem.selected {
  font-weight: 600;
  cursor: default;
  background: #89e28936;
  color: green;
}

.listItem.selected :global(.mdi-icon) {
  color: green;
}

.listItem.selected:hover {
  background: #89e28936;
  cursor: default;
}
.listItem.selected.allowUnselect:hover {
  background: #7fd27f36;
  cursor: pointer;
}

.listItem :global(.mdi-icon) {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  color: #c6ccce;
}

.listItem :global(.mdi-icon).deleteIcon {
  color: #1c253a;
  width: 20px;
  height: 20px;
  margin-right: 0;
}

.listItem:first-child {
  border-radius: 8px 8px 0 0;
}

.listItem .label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.emptyMessage {
  flex: 1;
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
  opacity: 0.4;
}