.product-file-upload-link {
    display: flex;
    height: 32px;
    font-size: 13px;
    line-height: 32px;
    border-radius: 16px;
    color: #960048;
    background: rgba(150, 0, 72, 0.16);
    line-height: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 4px;
    font-weight: 500;
    transition: color 0.1s, background 0.1s;
}


.product-file-upload-link .mdi-icon {
    width: 16px;
    height: 16px;
    margin: 4px 4px 0 0;
    fill: currentColor;
    transition: fill 0.1s;
}

.product-file-upload-link.has-value {
    background: rgba(76, 175, 80, 0.19);
    color: #349838;
}

.product-file-upload-link .indicator .upload-icon {
    display: none;
}

.product-file-upload-link:hover .indicator .upload-icon {
    display: block;
}
.product-file-upload-link:hover .indicator .upload-status {
    display: none;
}

.product-file-upload-link:hover {
    background: #960048;
    color: #fff;
}

.product-file-upload-link.has-value:hover {
    background: #008275;
    color: #fff;
}