.preloader-wrapper.tiny {
    width: 20px;
    height: 20px;
}

.preloader-wrapper .circle-clipper .circle {
    border-width: 2px;
}

.preloader-wrapper .spinner-layer.spinner-blue-only {
    border-color: #26a69a;
}