.container {
  width: 100%;
  height: 400px;
  background: #fff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.container.noGeoData {
  height: auto;
}

.container :global(.leaflet-container) {
  height: 100%;
}