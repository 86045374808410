.section {
  background: #fff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.body {
  padding: 0;
}

.header {
  padding: 0 16px 16px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.headerLeft {
  flex: 1;
}

.title {
  font-weight: bold;
}

.subTitle {
  font-size: 12px;
  font-weight: normal;
}

.action {
  display: flex;
}

.action button {
  background: #3d485c;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 0px;
  text-align: center;
  color: #fff;
  margin-left: 12px;
  cursor: pointer;
  padding: 0;
  border: none;
  box-shadow: none;
}

.action button:hover {
  background: #49556b;
}

.action button:active {
  background: #384355;
}

.action button :global(.mdi-icon) {
  margin: 0 auto;
  width: 16px;
  height: 16px;
}