.alert {
    padding: 8px;
    border-radius: 2px;
    margin: 16px 0;
}

.alert.warning {
    background: #fff59f;
    color: #3c370b;
    border: solid 1px #FFEB3B;
    
}

.alert.info {
    background: #d9eaef;
    color: #2b6c86;
    border: solid 1px #2b6c86;
}