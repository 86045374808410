.login-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100vh;
}
.login-container {
  width:400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -200px;
}


.login-form {
    background: #fff;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 1px 12px 48px rgba(0,0,0,.14);
}

.login-form h5 {
  margin: 0 0 24px 0;
}

.login-error {
  margin: 8px 0;
  color: #F44336;
}

.login-footer {
  display: flex;
  margin-top: 16px;
  align-items: center;
}

.is-fetching {
  opacity: 0.5;
  margin-left: 16px;
}

.spinner.preloader-wrapper {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.spinner.preloader-wrapper .spinner-blue-only {
  border-color: #26a69a
}
  

.logo-wrapper .logo-wrapper-inner {
  display: flex;
  margin: 0 auto 32px 0;
}

.logo-wrapper .logo-wrapper-inner .center{
  text-align: center;
  margin: 0 auto;
  display: flex;
}

.logo-wrapper img {
  width: auto;
  height: 36px;
}

.logo-wrapper .title {
  letter-spacing: 3px;
  background: #1d406f;
  color: #fff;
  border-radius: 2px;
  height: 34px;
  line-height: 31px;
  padding: 0 2px 0 5px;
  margin-left: 8px;
  font-size: 28px;
  margin-top: 1px;
}