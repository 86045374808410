.container {
  height: auto;
  max-width: 350px;
  width: 350px;
  position: relative;
  display: flex;
}

.container :global(.mdi-icon) {
  position: absolute;
  left: 10px;
  top: 9px;
  width: 24px;
  height: 24px;
  color: rgb(158, 158, 158);
  transition: color 0.15s;
}

.hasFocus :global(.mdi-icon) {
  color: #26a69a;
}

.clearButton {
  width: 35px;
  position: absolute;
  bottom: 12px;
  right: 3px;
  top: 3px;
  height: 35px;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  color: #7d8897;
}

.clearButton:hover :global(.mdi-icon),
.clearButton:focus :global(.mdi-icon) {
  color: #7d8897;
}

.clearButton:hover,
.clearButton:focus,
.clearButton:active {
  background: none !important;
} 

.clearButton :global(.mdi-icon) {
  width: 20px;
  height: 20px;
  color: #9e9e9e;
  position: static;
  margin: 0 auto;
}

:global(.preloader-wrapper.active.small).spinner {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 12px;
  right: 12px;
}

:global(.preloader-wrapper.active.small).spinner .circle {
  border-width: 2px;
}

:global(input[type=search]).inputField {
  margin: 0;
  padding: 8px 38px 8px 42px;
  height: auto;
  width: 100%;
  background: #fff;
  font-weight: 500;
  border-radius: 8px;
  transition: box-shadow .15s, border .15s;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
}

:global(input[type=search]).inputField:hover {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
  background: #fff !important;
}

:global(input[type=search]).inputField:focus {
  border: solid 1px #26a69a !important;
  background: #fff !important;
  box-shadow: 0 1px 4px #00000017 !important;
}