.left {
  width: auto;
  position: relative;
}

.coverImage {
  width: 86px;
  height: 86px;
  margin: 16px;
  border-radius: 4px;
}

.profileImage {
  width: 86px;
  height: 86px;
}

.name {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  top: 25px;
  text-shadow: 0px 0px 5px #000
}

.right {
  flex: 1;
}

.rightTop {
  padding: 16px 16px 16px 0;
  font-weight: 500;
  border-bottom: solid 1px #eee;
}

.titleWrapper {
  flex: 1;
}

.title {
  font-weight: 500;
}

.publisherNumber {
  font-size: 12px;
}


.rightBottom {
  display: flex;
  padding: 0px 16px 16px 0;
  flex-wrap: wrap;
}

.isPublic {
  width: 16px;
  height: 16px;
  color: green;
  margin-right: 4px;
}

.isNotPublic {
  composes: isPublic;
  color: #374e59;
}

.publisherNumber {
  margin-right: 16px;
}

.element {
  padding-top: 8px;
  margin-right: 16px;
}

.emailElement {
  composes: element;
  width: 250px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.isbnElement {
  composes: element;
  width: 120px;
  max-width: 120px;
}

.publicationDateElement {
  composes: element;
  width: 120px;
  max-width: 120px;
}

.editionElement {
  composes: element;
  width: 120px;
  max-width: 120px;
}

.dataElement {
  composes: element;
  width: 130px;
  max-width: 130px;
}

.shop {
  composes: element;
  width: 100px;
  max-width: 100px;
}

.shop a {
  display: flex;
  align-items: center;
}

.shop a :global(.mdi-icon) {
  width: 14px;
  height: 14px;
  margin-left: 2px;
}

.label {
  color: #73777f;
  font-size: 12px;
}

.value {}

.valueTwoItems {
  display: flex;
  align-items: center;
}

.typeIcon {
  width: 16px;
  height: 16px;
  color: #374e59;
}

.typeImage {
  width: 100%;
  height: 100%;
  color: #fff;
}

.typeImage.isLoadingImage,
.typeImage.isErrorImage {
  color: #374e59;
}

.warningIcon {
  composes: typeIcon;
  color: rgb(246, 88, 17);
}

.okIcon {
  composes: typeIcon;
  color: rgb(96, 153, 8);
}

.dataItem {
  display: flex;
  border-radius: 12px;
  padding-left: 3px;
  align-items: center;
}

.validDataItem :global(.mdi-icon) {}


.invalidDataItem {
  background: rgb(255 238 207);
  color: rgb(201, 131, 3);
  width: 66px;
}

.invalidDataItem :global(.mdi-icon) {
  color: orange;
}

.invalidDataItem div {
  margin-left: 4px;
}


.kompass-table.product-table thead tr th,
.kompass-table.product-table tbody tr td {
  line-height: 18px
}

.kompass-table.product-table tbody tr td .cover-wrapper {
  background: red;
}

.shop-link {
  background: #f3f4f5;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  transition: box-shadow 0.1s;
}

.shop-link:first-child {
  margin-right: 8px;
}

.shop-link:last-child {
  margin-right: 0px;
}


.shop-link img,
.shop-link .mdi-icon {
  width: 16px;
  height: 16px;
  color: #1d406f;
}

.shop-link:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #008275;
}

.remove-link {
  font-weight: 500;
  cursor: pointer;
}

.remove-link:hover {
  text-decoration: underline;
}

.status-labels {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.status-labels .unpublished:first-child {
  margin-left: 0;
}

.unpublished {
  margin-left: 4px;
  background: rgba(28, 64, 111, 0.1);
  color: #1c406f;
  padding: 0 4px;
  border-radius: 2px;
  display: flex;
  font-weight: bold;
  align-items: center;

}

.unpublished>span {
  margin-left: 4px;
}

.unpublished .mdi-icon {
  width: 14px;
  height: 14px;
}

.kompass-table.product-table tfoot tr {
  border-top: 3px solid rgb(239, 239, 240);
}

.pagination {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.pagination>li {
  margin-right: 8px;
  display: flex;
  align-items: center;
  text-align: center;
}


.pagination>li>a {
  width: 32px;
  height: 32px;
  display: block;
  background: rgba(28, 64, 111, 0.1);
  color: #1c406f;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  padding: 0;
}

.pagination>li>a:hover {
  background: #cdedeb;
}

.pagination>li>a:active {
  background: #c3e7e4;
}

.pagination>li.selected>a,
.pagination>li.selected>a:hover {
  background: #26a69a;
  color: #fff;
  cursor: default;
}

.pagination>li.disabled,
.pagination>li.selected {
  pointer-events: none;
}

.pagination>li.disabled>a {
  color: rgba(28, 64, 111, 0.37);
  cursor: default;
}

.pagination>li:last-child {
  margin-right: 0;
}

.kompass-table.product-table .result-size {
  margin: 0 0 0 auto;
  color: rgb(28, 37, 58, 0.6);
}

.editButton {
  border-radius: 4px;
  border: none;
  height: auto;
  width: auto;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  user-select: none;
  background-color: #fff;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
  color: #7d8897;
  height: 28px;
  padding: 0 6px 0 4px;
  font-size: 14px;
  transition: background-color 0.15s, border 0.15s, box-shadow 0.15s;
}

.editButton:hover,
.editButton:focus {
  background-color: #f3f8ff;
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
}

.editButton:active {
  background-color: #edf2fb;
  border: solid 1px #1c406f;
  box-shadow: 0 1px 4px #00000017;
}

.editButton>.pencilIcon {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin: 0 auto;
}

.editButton>div {
  padding-left: 4px;
  font-size: 14px;
}