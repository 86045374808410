.body {
  padding: 12px 24px 0 24px;
  background: #f3f4f5;
  overflow-y: auto;
}

.body :global(.row) {
  margin: 0;
}

.tab {
  padding: 0 !important;
}

:global(.tab.disabled) {
  display: none;
}

