:root {
  --kom-c-primary-500: #008275;
  --kom-c-secondary-500: #1C406F;
  --kom-c-accent-500: #960048;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #f3f4f5;
  color: #1c253a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-app {
  background: linear-gradient(#fff, #ccd4dd);
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.background {
  height: 100vh;
  position: absolute;
  width: 100vw;
  background: url(./bg.svg);
  background-size: cover;
  background-position: 0 320px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.background > div {
  margin: -100px auto 0 auto;
  text-align: center;
}

.background > div > span {
  font-size: 24px;
  margin-top: 16px;
  display: block;
  opacity: 0.5;
  padding-left: 16px;
}

.init-spinner.preloader-wrapper .spinner-blue-only {
  border-color: #26a69a
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.ReactModal__Content {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 70%;
  width: 600px;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
}

.ReactModal__Content--after-open {
  z-index: 100003;
  display: block;
  opacity: 1;
  top: 10%;
  transform: scaleX(1) scaleY(1);
}

.ReactModal__Content.modal .modal-footer {
  margin-bottom: 22px;
  padding-right: 24px;
}