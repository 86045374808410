.text-editor .ql-toolbar.ql-snow {
    border: none;
    border-bottom: solid 2px #ddd;
    font-family: inherit;
}

.text-editor .ql-container.ql-snow  {
    border: none;
}

.text-editor .ql-container.ql-snow .ql-editor {
    font-family: Georgia, "Times New Roman", "Bitstream Charter", Times, serif;
    font-size: 16px;
    line-height: 1.5;
    color: #1c253a;
    max-width: 100%;
    -webkit-font-smoothing: antialiased !important;
    overflow-wrap: break-word;
}

.text-editor .ql-snow .ql-editor strong,
.text-editor .ql-snow .ql-editor b {
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.text-editor .ql-snow .ql-editor h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text-editor .ql-snow .ql-editor blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    border-left: none;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 0px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    z-index: 1000;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent;
    outline: none;
}

.ql-snow.ql-toolbar .ql-picker-label {
    color: #47536d;
    border-radius: 2px;
}

.ql-snow.ql-toolbar .ql-picker-label:hover {
    color: #1c253a;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    color: #008275;
    background: #d7eae9;
}

.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
    stroke: #1c253a;
}

.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill {
    fill: #1c253a;
}

.ql-snow .ql-picker {
    color: #47536d;
}

.ql-snow.ql-toolbar .ql-picker-item {
    outline: none;
}


.ql-snow.ql-toolbar .ql-picker-item:hover {
    color: #1c253a;
}

.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke {
    stroke: #1c253a;
}

.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill {
    fill: #1c253a;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    stroke: #008275;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill {
    fill: #008275;
}

.text-editor .ql-snow.ql-toolbar .ql-stroke {
    stroke: #47536d  
}

.text-editor .ql-snow.ql-toolbar .ql-fill {
    fill: #47536d   
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: #47536d
}

.text-editor .ql-snow.ql-toolbar button:hover {
    color: #1c253a;
}

.text-editor .ql-snow.ql-toolbar button:hover .ql-stroke {
    stroke: #1c253a;   
}


.text-editor .ql-snow.ql-toolbar button:hover .ql-fill {
    fill: #1c253a;
}

.ql-snow.ql-toolbar button.ql-active {
    background: #d7eae9;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: #008275;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
    fill: #008275;
}

.ql-snow.ql-toolbar .ql-readmore {
    width: auto;
}
.ql-snow.ql-toolbar .ql-readmore:hover {
    color: #1c253a;
}

.ql-editor .readmore {
    position: relative;
    border: none;
    border-top: dotted 1px #018275;
    margin: 32px 0;

}

.ql-editor .readmore:after {
    content: 'Darüber: Intro';
    font-size: 12px;
    position: absolute;
    left: 0px;
    top: -20px;

    padding: 0 4px;
    font-family: sans-serif;
    background: #018275;
    color: #fff;
    border-radius: 2px 2px 0 0;
}

.ql-editor .readmore:before {
    content: 'Darunter: Erweitert';
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 1px;
    padding: 0 4px;
    font-family: sans-serif;
    background: #018275;
    color: #fff;
    border-radius: 0 0 2px 2px;
}