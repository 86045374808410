.summary {
  font-weight: 500;
  padding: 12px 8px;
  display: flex;
  border-top: none;
  margin-top: 0;
  color: #374e59;
  background: rgb(224, 229, 235);
  border-radius: 8px;
  margin-bottom: 12px;
}


.summaryWrapper {
  margin-top: 12px;
  position: relative;
  border-radius: 8px;
  margin-right: -4px;
}

.summaryWrapper .summary:last-child {
  margin-bottom: 0;
}

.summary:global(.success) {
  color: green;
  background: rgba(6, 190, 6, 0.21);
}

.summary:global(.warning) {
  color: orange;
  background: rgba(255, 165, 0, 0.19);
}

.summary:global(.warning) > div {
  color: rgb(201, 131, 3);
}

.summary:global(.inactive) {
  color: #374e59;
}

.summary :global(.mdi-icon) {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: currentColor;
}

.summary div {
  flex: 1;
  color: inherit;
  font-weight: 600;
}

.summary b {
  border-bottom: solid 3px;
}

.warning {
  background: rgba(255, 165, 0, 0.19);
  color: rgb(207, 134, 0);
  padding: 12px;
  border-radius: 8px;
}