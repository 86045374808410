.isLoading {
  opacity: 0.5;
  pointer-events: none;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination ul {
  flex: 1;
}

.pagination ul li a {
  width: auto;
  min-width: 32px;
  padding-left: 2px;
  padding-right: 2px;
}

.resultSize {
  margin: 0 0 0 auto;
  color: rgb(28, 37, 58, 0.6);
}

.resultCount {
  font-weight: bold;
  margin-bottom: 16px;
  color: rgb(28 37 58);
}