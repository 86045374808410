.kompass-table.product-table thead tr th,
.kompass-table.product-table tbody tr td {
    line-height: 18px
}

.kompass-table.product-table tbody tr td .cover-wrapper {
    background: red;
}

.shop-link {
    background: #f3f4f5;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    transition: box-shadow 0.1s;
}

.shop-link:first-child {
    margin-right: 8px;
}

.shop-link:last-child {
    margin-right: 0px;
}


.shop-link img,
.shop-link .mdi-icon {    width: 16px;
    height: 16px;
    color: #1d406f;
}

.shop-link:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #008275;
}

.remove-link {
    font-weight: 500;
    cursor: pointer;
}

.remove-link:hover {
    text-decoration: underline;
}

.status-labels {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.status-labels .unpublished:first-child {
    margin-left: 0;
}

.unpublished {
    margin-left: 4px;
    background: rgba(28, 64, 111, 0.1);
    color: #1c406f;
    padding: 0 4px;
    border-radius: 2px;
    display: flex;
    font-weight: bold;
    align-items: center;

}

.unpublished > span {
    margin-left: 4px;
}

.unpublished .mdi-icon {
    width: 14px;
    height: 14px;
}

.kompass-table.product-table tfoot tr {
    border-top: 3px solid rgb(239, 239, 240);
}

.pagination {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.pagination > li {
    margin-right: 8px;
    display: flex;
    align-items: center;
    text-align: center;
}


.pagination > li > a {
    width: 32px;
    height: 32px;
    display: block;
    background: rgba(28, 64, 111, 0.1);
    color: #1c406f;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    padding: 0;
}

.pagination > li > a:hover {
    background: #cdedeb;
}

.pagination > li > a:active {
    background: #c3e7e4;
}

.pagination > li.selected > a,
.pagination > li.selected > a:hover {
    background: #26a69a;
    color: #fff;
    cursor: default;
}

.pagination > li.disabled,
.pagination > li.selected {
    pointer-events: none;
} 

.pagination > li.disabled > a {
    color: rgba(28, 64, 111, 0.37);
    cursor: default;
}

.pagination > li:last-child {
    margin-right: 0;
}

.kompass-table.product-table .result-size {
    margin: 0 0 0 auto;
    color: rgb(28, 37, 58, 0.6);
}