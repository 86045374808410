.section {
  padding: 0 0 24px 0;
}

.sectionTitle {
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 16px;
}

.sectionInfo {
  padding: 8px 0;
  font-size: 12px;
}

:global(.modal-footer) {
  padding-right: 24px;
  background: #fff !important;
}

.appRadio label {
  display: block;
}

.checkbox :global([type="checkbox"]+span:not(.lever)) {
  height: 20px;
  line-height: 20px;
}

.checkbox :global([type="checkbox"]+span) {
  color: #374e59;
}

.checkbox :global([type="checkbox"].filled-in:checked+span) {
  font-weight: 500;
}


.subTitle {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal:global(.modal) {
  border-radius: 0;
  height: 100vh;
  max-height: 100vh;
  top: 0;
  background: #f3f4f5 !important;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.modal:global(.modal) :global(.modal-content) {
  flex: 1;
}

.content {
  width: 600px;
  margin: 0 auto;
}


.modalHeader {
  background: #fff;
  border-radius: 12px 12px 0 0;
  font-size: 20px;
  padding: 24px;
  font-weight: 500;
  border-bottom: solid 1px #dedede;
  position: sticky;
  top: 0;
  z-index: 1;
}

.modalFooter {
  background: #fff;
  margin: 0;
  padding: 24px;
  height: auto;
  border-top: solid 1px #dedede;
  border-radius: 0 0 12px 12px;
  position: sticky;
  bottom: 0;
}

.label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  color: rgb(108, 112, 116);
  display: block;
}

:global(input[type=text]).textInput {
  margin: 0;
  box-sizing: border-box;
  padding: 8px 12px 8px 12px;
  height: auto;
  width: 100%;
  background: #fff;
  font-weight: 500;
  border-radius: 8px;
  transition: box-shadow .15s, border .15s;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
}

:global(input[type=text]).textInput:hover {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
  background: #fff !important;
}

:global(input[type=text]).textInput:focus {
  border: solid 1px #26a69a !important;
  background: #fff !important;
  box-shadow: 0 1px 4px #00000017 !important;
}

:global(input[type=text]).textInput,
:global(textarea).textArea {
  margin: 0;
  box-sizing: border-box;
  padding: 8px 12px 8px 12px;
  height: auto;
  width: 100%;
  background: #fff;
  font-weight: 500;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: box-shadow .15s, border .15s;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
}

:global(input[type=text]).textInput:hover,
:global(textarea).textArea:hover {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
  background: #fff !important;
}

:global(input[type=text]).textInput:focus,
:global(textarea).textArea:focus {
  border: solid 1px #26a69a !important;
  background: #fff !important;
  box-shadow: 0 1px 4px #00000017 !important;
}

.imageContainer {
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
  display: flex;
  margin-bottom: 12px;
}

.imageWrapper {
  flex: 1;
}

.imageInfo {
  flex: 1;
  padding-left: 16px;
  font-size: 14px;
}

.imageInfo h5 {
  margin: 0 0 8px 0;
  padding: 0;
  font-weight: bold;
}

.imageInfo ul {
  padding-left: 14px;
  margin-bottom: 8px;
}

.imageInfo ul>li {
  list-style: initial;
}

.emptyState {
  display: flex;
  align-items: center;
  height: 100%;
}
.emptyState > div {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.emptyState > div > :global(.mdi-icon) {
  margin: 0 auto;
  color: #ccc;
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
}

.imageWrapper img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  max-height: 180px;
}

.emptyImage {}

.fileDropZone {
  height: 180px;
  width: 100%;
  border: dashed 2px #ddd;
  border-radius: 8px;
  position: relative;
}

.actions {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
}

.action {
  background: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: rgb(28, 37, 58);
}

.action:hover,
.action:focus {
  background-color: #f3f8ff;
}

.action :global(.mdi-icon) {
  margin: 0 auto;
}

.fileDropZone.hasImage {
  border-color: transparent;
}

.fileDropZone .upload-icon {
  width: 64px;
  height: 64px;
  color: #ddd;
  margin: 0 auto;
}

.fileDropZone .not-allowed {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 75%;
  padding: 2px;
  margin: 2px;
  opacity: 0;
  transition: opacity 0.2s;
}

.fileDropZone.drag-reject .not-allowed {
  opacity: 1.0;
}

.fileDropZone .file-selected-icon {
  width: 64px;
  height: 64px;
  color: #008275;
  margin: 0 auto;
}

.fileDropZone .file-info {
  font-weight: 500;
  margin: 16px 0 0 0;
}

.fileDropZone .file-info.empty {
  color: #ddd;
}

.fileDropZone a {
  font-weight: 500;
  color: #008275;
  cursor: pointer;
}

.fileDropZone a:hover {
  border-bottom: solid 2px;
}

.file-drop-zone p {
  margin: 16px 0 0 0;
}

.file-drop-zone .allowed-file-types {
  font-size: 0.85rem;
  margin: 0;
  color: #666;
}

.smallSpinner {
  width: 24px;
  height: 24px;
  margin: 0 auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  pointer-events: none;
}

.overlaySpinner {
  margin: 0 auto;
}