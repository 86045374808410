

  .file-drop-zone {
    border: dashed 2px #ddd;
    padding: 48px 24px;
    text-align: center;
    position: relative;
    transition: border-color 0.2s;
  }

  .file-drop-zone .upload-icon {
    width: 64px;
    height: 64px;
    color: #ddd;
    margin: 0 auto;
  }

  .file-drop-zone .not-allowed {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 75%;
    padding: 2px;
    margin: 2px;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .file-drop-zone.drag-reject .not-allowed {
      opacity: 1.0;
  }
  .file-drop-zone .file-selected-icon {
    width: 64px;
    height: 64px;
    color: #008275;
    margin: 0 auto;
  }

  .file-drop-zone .file-info {
    font-weight: 500;
    margin: 16px 0 0 0;
  }

  .file-drop-zone .file-info.empty {
      color: #ddd;
  }

  .file-drop-zone a {
      font-weight: 500;
      color: #008275;
      cursor: pointer;
  }

  .file-drop-zone a:hover {
    border-bottom: solid 2px;
  }

  .file-drop-zone p {
    margin: 16px 0 0 0;
  }

  .file-drop-zone .allowed-file-types {
      font-size: 0.85rem;
      margin: 0;
      color: #666;
  }

  .file-drop-zone .spinner-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fafafa;
  }

  .file-drop-zone .spinner-container > div {
      margin: 0 auto;
  }

  :global(.modal-foote) {
    padding-right: 24px;
  }