.container {
  align-items: left;
  padding-top: 164px;
  position: fixed;
  left: 0;
  top: 0;
  width: 320px;
  height: 100vh;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
  border-right: solid 1px #eee;
  overflow-y: auto;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  flex: 1;
}

.resetLink {
  font-size: 12px;
  font-weight: 600 !important;
  letter-spacing: 0.6px;
  color: #374e59 !important;
  background: rgb(224, 229, 235) !important;
  user-select: none;
  width: 28px;
  height: 28px;
  border-radius: 8px;

}

.resetLink :global(.mdi-icon) {
  width: 18px;
  height: 18px;
  margin: 0 auto;
}

.resetLink:hover,
.resetLink:focus,
.resetLink:active {
  color: #374e59 !important;
  background: rgb(213, 218, 224) !important;
}

.filterElement {
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
}

.filterElement :global([type="checkbox"]+span:not(.lever)) {
  height: 20px;
  line-height: 20px;
}