.fileDropZone {
  height: 180px;
  width: 100%;
  border: dashed 2px #ddd;
  border-radius: 8px;
  position: relative;
}

.actions {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
}

.action {
  background: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: rgb(58 98 131);
  margin-left: 8px;
}

.action:hover,
.action:focus {
  color: rgb(28, 37, 58);
  background-color: #f3f8ff;
}

.action :global(.mdi-icon) {
  margin: 0 auto;
}

.fileDropZone.hasImage {
  border-color: transparent;
}

.fileDropZone .upload-icon {
  width: 64px;
  height: 64px;
  color: #ddd;
  margin: 0 auto;
}

.fileDropZone .not-allowed {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 75%;
  padding: 2px;
  margin: 2px;
  opacity: 0;
  transition: opacity 0.2s;
}

.fileDropZone.drag-reject .not-allowed {
  opacity: 1.0;
}

.fileDropZone .file-selected-icon {
  width: 64px;
  height: 64px;
  color: #008275;
  margin: 0 auto;
}

.fileDropZone .file-info {
  font-weight: 500;
  margin: 16px 0 0 0;
}

.fileDropZone .file-info.empty {
  color: #ddd;
}

.fileDropZone a {
  font-weight: 500;
  color: #008275;
  cursor: pointer;
}

.fileDropZone a:hover {
  border-bottom: solid 2px;
}

.selectFileButton {
  font-weight: 500;
  color: #008275;
  cursor: pointer;
  padding: 0;
  border-bottom: solid 2px transparent;
}

.selectFileButton:hover,
.selectFileButton:focus {
  border-bottom: solid 2px;
  background: none;
  outline: none;
}

.file-drop-zone p {
  margin: 16px 0 0 0;
}

.file-drop-zone .allowed-file-types {
  font-size: 0.85rem;
  margin: 0;
  color: #666;
}

.smallSpinner {
  width: 24px;
  height: 24px;
  margin: 0 auto;
}


.emptyState {
  display: flex;
  align-items: center;
  height: 100%;
}
.emptyState > div {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.emptyState > div > :global(.mdi-icon) {
  margin: 0 auto;
  color: #ccc;
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
}

