.box {
    background: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    flex: 1;
}

.box .box-title {
    display: flex;
    padding: 16px;
    font-weight: 500;
    color: #1d406f;
    font-size: 1.1rem;
    border-bottom: solid 1px #dadada5c;
    background: #1d406f05;
    border-radius: 4px 4px 0 0;
}

.box.with-search .box-title > span {
    display: flex;
    flex: 1;
    padding-top: 12px;
}

.box.with-actions .box-title {
    padding: 10px 16px;
}

.box.with-actions .box-title > span {
  flex: 1;
  padding-top: 4px;
}

.box.with-search .box-title > input {
    margin: 0;
    padding: 8px 0;
    height: auto;
    max-width: 400px;
}

.box.with-search .box-title > .table-search-box {
  height: auto;
  max-width: 400px;
  width: 400px;
  position: relative;
}

.box.with-search .box-title > .table-search-box .preloader-wrapper {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 8px;
}

.box.with-search .box-title > .table-search-box > input {
  margin: 0;
  padding: 8px 0;
  height: auto;
  width: 100%;
}


.box .box-title small {
    margin-left: 4px;
    font-weight: normal;
}

.box .box-content {
    padding: 16px;
}

.box .box-content p {
    margin-top: 0;
}

.box.with-search .box-content,
.box.with-table .box-content {
    padding: 0;
    overflow-x: auto;
}


.tabs {
    background: none;
    margin-bottom: 24px;
}

.tabs .tab a {
    color: #1c253a;
    font-weight: 500;
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 24px;
    width: auto;
    /*Custom Text Color*/
  }

  .tabs .tab li {
    margin: 0;
    padding: 0;
  }
  
  .tabs .tab a:hover {
    color:#26a69a;
    /*Custom Color On Hover*/
  }

  .tabs .tab a.active {
    color:#008275;
    /*Custom Text Color While Active*/
    background-color: none;
    /*Custom Background Color While Active*/
  }
  
  .tabs .tab a:focus.active {
    color:#008275;
    /*Custom Text Color While Active*/
    background-color: rgba(38, 166, 154, 0.2);
    /*Custom Background Color While Active*/
  }
  
  .tabs .indicator {
    background-color: #008275;
    /*Custom Color Of Indicator*/
  }