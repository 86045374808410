nav .sidenav-trigger {
    color: rgba(255, 255, 255,0.87) !important;
}

.nav-bar {
    background: var(--kom-c-secondary-500);
    background: linear-gradient(90deg,#1c406f,#215769);
    box-shadow: none;
    
}

.nav-bar .brand-logo {
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-left: -8px;
    user-select: none;
}

.nav-bar .brand-logo img {
    height: 28px;
    width: auto;
    pointer-events: all;
    cursor: pointer;
}

.nav-bar .brand-logo .title {
    letter-spacing: 3px;
    background: #fff;
    color: var(--kom-c-secondary-500);
    font-weight: bold;
    border-radius: 2px;
    height: 23px;
    line-height: 23px;
    padding: 0 2px 0 4px;
    margin-left: 8px;
    font-size: 16px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.nav-wrapper {
    width: 100vw;
    margin: 0 auto;
    padding: 0 24px;
}

.nav-wrapper .brand-logo {
    font-size: 1.5rem;
}

.nav-bar ul li a {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    position: relative;
    margin: 0 16px;
    padding: 0;
}

.nav-bar ul li a.dropdown-trigger {
    display: flex;
    align-items: center;
}

.nav-bar ul li a:hover {
    color: #fff;
    background: transparent;
}

.nav-bar ul li a.active {
    background:transparent;
    color: #fff;
    font-weight: bold;
}

.nav-bar ul li a:after {
    content: '';
    position: absolute;
    background: #fff;
    transform: scaleX(0);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    transform: scaleX(0);
    transition: transform 0.2s, background-color 0.2s;
}

.nav-bar ul li a:hover:after {
    transform: scaleX(1);
}

.nav-bar ul li a.active:after {
    transform: scaleX(1);
    background: #008275;
}

.nav-bar ul li a.active:hover {
    color: #fff;
}

.nav-bar ul li .dropdown-content li a {
    line-height: 22px;
    padding: 14px 16px;
    margin: 0;
    color: #333;
}

.nav-bar ul li .dropdown-content li a:after {
    content: none;
}

.nav-bar ul li .dropdown-content li a:hover {
    background: #caf7f2;
    
}

.nav-bar .user-trigger {
    color: #008275;
    padding: 0;
    margin: 16px 0 8px 16px;
    user-select: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: box-shadow 0.3s;
    position: relative;
}

.nav-bar .user-trigger img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.nav-bar .user-trigger:before {
    content: '';
    position: absolute;
    left: -16px;
    top: 4px;
    height: 24px;
    width: 1px;
    background: rgba(255, 255, 255, 0.25);
}

.nav-bar .user-trigger:hover {
    color: #007d71;
    box-shadow: 0 4px 24px #00000047;
}

.nav-bar .user-trigger:after {
    content: none;
}

@media screen and (max-width: 1170px) {
    .nav-wrapper {
        width: 100%;
        margin: 0;
        padding: 0 24px 0 24px;
    }
}

@media screen and (max-width: 992px) {
    .nav-wrapper {
        padding: 0;
    }
}

@media screen and (max-width: 600px) {
    .nav-bar .brand-logo {
        margin-top: 14px;
    }
}