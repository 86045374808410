.content {
    margin: 0 auto;
    padding: 0 24px 24px 24px;
}

@media screen and (max-width: 1170px) {
    .content {
        width: 100%;
        margin: 0;
    }
}