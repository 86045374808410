.buttonContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
  border-radius: 4px;
}

.buttonContainer:hover {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
}



.mainAction {
  display: flex;
  align-items: center;
  color: #7d8897;
  border-radius: 4px 0 0 4px;
  padding: 0 8px;
  min-width: 32px;
  height: 26px;
  border-right: solid 1px #d6d5d5;
  user-select: none;
}

.mainAction:focus,
.mainAction:active,
.mainAction:hover {
  background-color: #f3f8ff;
}

.mainActionLabel {
  font-size: 14px;
  margin-left: 4px;
}

.mainAction :global(.mdi-icon) {
  width: 16px;
  height: 16px;
  color: #7d8897;
  margin-top: 0px;
  margin: 0 auto;

}

.buttonContainer button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.buttonContainer button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}


.dropdownButton {
  background: transparent;
  border-radius: 4px;
  border: none;
  height: auto;
  width: auto;
  cursor: pointer;
  min-width: 32px;
  height: 26px;
  text-align: left;
  display: flex;
  align-items: center;
  user-select: none;
}

.label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  color: rgb(108, 112, 116);
}

.dropdownButton.kompass {
  width: 100%;
}

.dropdownButton>div:first-child {
  flex: 1;
}

.dropdownButton>svg {
  width: 20px;
  height: 20px;
  color: #27a69a;
  margin-top: 2px; width: 12px;
    height: 12px;
}

.dropdownButton>:global(.mdi-icon) {
  width: 20px;
  height: 20px;
  color: #7d8897;
  margin-top: 0px;
  margin: 0 auto;
}

.itemEmptyLabel {
  flex: 1;
  opacity: 0.4;
}

.itemLabel {
  font-weight: 500;
  flex: 1;
  padding-left: 8px;
}

.dropdownButton.open>.chevron {
  transform: rotate(180deg);
}

.dropdownButton:focus,
.dropdownButton:hover,
.dropdownButton.open {
  background-color: #f3f8ff;
  border: none;
}

.dropdownButton:active {
  background-color: #edf2fb;
  border: none;
  box-shadow: 0 1px 4px #00000017;
}


.dropdownMenu {
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 26px 32px rgb(0 0 0 / 10%);
  position: absolute;
  left: auto;
  right: 0;
  top: 32px;
  width: 200px;
  min-width: 100%;
  border-radius: 8px;
  padding: 4px;
  height: auto;
  overflow: auto;
  z-index: 1;
}

.dropdownMenu.kompass {
  width: 100%;
  min-width: 100%;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  cursor: pointer;
  user-select: none;
  color: #1c406f;
  border-radius: 4px;
}

.dropdownItem.allItems {
  position: relative;
  margin-bottom: 8px;
}

.dropdownItem.allItems:before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: -4px;
  right: -4px;
  height: 0px;
  border-bottom: dashed 1px #ddd;
}

.dropdownItem.unset {
  position: relative;
  margin-top: 8px;
}

.dropdownItem.unset:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  height: 0px;
  border-top: dashed 1px #ddd;
}

.dropdownItem:hover {
  background: #cdedeb;
}

.dropdownItemName {
  flex: 1;
}

.dropdownItem svg {
  width: 18px;
  height: 18px;
}

.dropdownItemLabel {
  flex: 1;
  padding-left: 8px;
}

.dropdownHeader {
  opacity: 0.6;
  font-size: 12px;
  padding: 8px 16px 0 16px;
}

.dropdownSeparator {
  margin: 8px 2px;
  border-top: solid 1px #ddd;
}