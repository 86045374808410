.loadingContainer {
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.loadingContainerInner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 12px 48px #00000005;
}

.loadingContainerInner :global(.mdi-icon) {
  width: 96px;
  height: 96px;
  opacity: 0.5;
}

.loadingMessage {
  font-size: 18px;
  opacity: 0.5;
}