.backdropContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.backdrop {
  flex: 1;
  height: 100%;
}

.container {
  width: 600px;
  height: 100vh;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  margin: 0 0 0 auto;
  overflow: hidden;
  border-radius: 12px 0 0 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.nav {
  height: 64px;
  min-height: 64px;
  top: 0;
  width: 100%;
  margin: 0 auto 0 0;
  background: var(--kom-c-secondary-500);
  background: linear-gradient(90deg,#1c406f,#215769);
  position: sticky;
  border-radius: 12px 0 0 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

.containerBackground {
  position: fixed;
  right: 0;
  width: 600px;
  background: #f3f4f5;
  height: calc(100% - 50px);
  top: 50px;
}


.closeButton {
  opacity: 1;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #b5bac3;
  cursor: pointer;
  transition: color 0.1s;
  user-select: none;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.closeButton :global(svg) {
  margin: 0 auto;
}

.closeButton:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.overlayTitle {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 16px;
}

@media screen and (max-width: 600px) {
  .container {
    height: 100vh;
    border-radius: 0 0 0 0;
  }

  .nav {
    border-radius: 0;
  }
}