.container {
  height: 48px;
  width: 28px;
  display: flex;
  align-items: center;
}

.container > img {
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 4px;
}

.background {
  height: 100%;
  max-width: 100%;
  background: #eee;
}