.kompass-table > thead > tr {
    border-bottom: 3px solid rgb(239, 239, 240);
}

.kompass-table thead tr th {
    padding: 16px;
    font-weight: 500;
    vertical-align: top;
    font-weight: 600;
}

.kompass-table tbody tr td {
    cursor: default;
}

.kompass-table tbody tr:hover {
    background: #fff;
    cursor: default;
}

.kompass-table.selectable tbody tr:hover,
.kompass-table.clickable tbody tr:hover {
    background-color: #f3f7f6;    
}


.kompass-table tbody tr td {
    padding: 12px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.kompass-table tbody tr td.with-icon {
    padding: 0 16px;
}

.kompass-table.selectable tbody tr td,
.kompass-table.clickable tbody tr td {
    cursor: pointer;
}

.kompass-table.selectable tbody tr td:first-child {
    position: relative;
}

.kompass-table tbody tr td.empty {
    padding: 32px 16px;
    color: #9e9e9e;
    background: #fff;
    pointer-events: none;
}

.kompass-table tbody tr:last-child {
    border-bottom: none;
}

.kompass-table.selectable tbody tr.selected {
    font-weight: 500;
    background-color: #e8fffd;
}

.kompass-table.selectable tbody tr.selected:hover {
    background-color: #e8fffd;
}

.kompass-table.selectable tbody tr.selected td:first-child:after {
    content: '';
    width: 4px;
    height: 100%;
    background: #009e8f;
    position: absolute;
    left: 0;
    top: 0;
}

.kompass-table.selectable tbody tr.disabled {
    background: #fff;
}

.kompass-table.selectable tbody tr.disabled td {
    cursor: default;
}

.kompass-table tbody tr.is-loading {
    opacity: 0.5;
    pointer-events: none;
}