.left {
    width: 90px;
}

.coverImage {
    height: 96px;
    width: auto;
    margin: 16px;
}

.right {
    flex: 1;
}

.rightTop {
    padding: 16px 16px 0px 0;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
}

.titleLeft {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

@media screen and (max-width: 1280px) {
    .titleLeft {
        display: block;
    }

    .badges {
        margin-bottom: 12px;
        margin-right: 0;
    }

    .badges div:last-child:after {
        content: none !important;
    }
}


.titleWrapper {
    flex: 1;
}

.title {
    font-weight: 500;
}

.publisherNumber {
    font-size: 12px;
    margin-bottom: 8px;
}


.rightBottom {
    display: flex;
    padding: 0px 16px 16px 0;
    border-top: solid 1px #eee;
    flex-wrap: wrap;
}

.isPublic {
    width: 16px;
    height: 16px;
    color: green;
    margin-right: 4px;
}

.isNotPublic {
    composes: isPublic;
    color: #374e59;
}

.publisherNumber {
    margin-right: 16px;
}

.element {
    padding-top: 8px;
    margin-right: 16px;
}

.isbnElement {
    composes: element;
    width: 170px;
    max-width: 170px;
}

.publicationDateElement {
    composes: element;
    width: 120px;
    max-width: 120px;
}

.editionElement {
    composes: element;
    width: 140px;
    max-width: 140px;
}

.gpxElement {
    composes: element;
    width: 100px;
    max-width: 100px;
}

.downloadTypeElement {
    composes: element;
}

.dataElement {
    composes: element;
    width: 100px;
    max-width: 100px;
}

.shop {
    composes: element;
    width: 100px;
    max-width: 100px;
}

.shop a {
    display: flex;
    align-items: center;
}

.shop a :global(.mdi-icon) {
    width: 14px;
    height: 14px;
    margin-left: 2px;
}

.badges {
    display: flex;
    align-items: center;
}

.badge {
    color: #fff;
    font-size: 12px;
    margin-right: 8px;
    border-radius: 4px;
    height: 28px;
    padding: 0 6px 0 4px;
    display: flex;
    align-items: center;
    position: relative;
}

.badge b {
    flex: 1;
    text-align: right;
}

.badge :global(.mdi-icon) {
    width: 16px;
    margin-right: 4px;
}

.badge:last-child {
    margin-right: 0px;
}

.badgeGreen {
    composes: badge;
    color: green;
    border: solid 1px rgb(178 208 178);
    background: rgb(202 241 202);
}

.badgeGrey {
    composes: badge;
    color: #6e8791;
    background: #f4f6f7;
    border: solid 1px #d6d5d5;
}

.badgeWarning {
    composes: badge;
    color: #ed9900;
    border: solid 1px rgb(245 216 163);
    background: rgb(255 244 223);
    font-weight: 500;
}

.badges .badge:last-child:after {
    content: '';
    position: absolute;
    right: -13px;
    top: 3px;
    height: 20px;
    width: 1px;
    background: #d6d5d5;
}

.unpublishedBadge {
    min-width: 96px;
}

.warningBadge {
    min-width: 72px;
}

.badgeUnlockable {
    composes: badge;
    background: #434c61;
}

.label {
    color: #73777f;
    font-size: 12px;
}

.value {}

.valueTwoItems {
    display: flex;
    align-items: center;
}

.typeIcon {
    width: 16px;
    height: 16px;
    color: rgb(224, 229, 235);
}

.warningIcon {
    composes: typeIcon;
    color: rgb(246, 88, 17);
}

.okIcon {
    composes: typeIcon;
    color: rgb(96, 153, 8);
}

.simpleIcon {
    composes: typeIcon;
    color: #374e59;
}

.dataItem {
    display: flex;
    border-radius: 12px;
    padding-left: 3px;
    align-items: center;
}

.validDataItem :global(.mdi-icon) {}


.invalidDataItem {
    background: rgb(255 238 207);
    color: rgb(201, 131, 3);
    width: 66px;
}

.invalidDataItem :global(.mdi-icon) {
    color: orange;
}

.invalidDataItem div {
    margin-left: 4px;
}


.kompass-table.product-table thead tr th,
.kompass-table.product-table tbody tr td {
    line-height: 18px
}

.kompass-table.product-table tbody tr td .cover-wrapper {
    background: red;
}

.shop-link {
    background: #f3f4f5;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    transition: box-shadow 0.1s;
}

.shop-link:first-child {
    margin-right: 8px;
}

.shop-link:last-child {
    margin-right: 0px;
}


.shop-link img,
.shop-link .mdi-icon {
    width: 16px;
    height: 16px;
    color: #1d406f;
}

.shop-link:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #008275;
}

.remove-link {
    font-weight: 500;
    cursor: pointer;
}

.remove-link:hover {
    text-decoration: underline;
}

.status-labels {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.status-labels .unpublished:first-child {
    margin-left: 0;
}

.unpublished {
    margin-left: 4px;
    background: rgba(28, 64, 111, 0.1);
    color: #1c406f;
    padding: 0 4px;
    border-radius: 2px;
    display: flex;
    font-weight: bold;
    align-items: center;

}

.unpublished>span {
    margin-left: 4px;
}

.unpublished .mdi-icon {
    width: 14px;
    height: 14px;
}

.kompass-table.product-table tfoot tr {
    border-top: 3px solid rgb(239, 239, 240);
}

.pagination {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.pagination>li {
    margin-right: 8px;
    display: flex;
    align-items: center;
    text-align: center;
}


.pagination>li>a {
    width: 32px;
    height: 32px;
    display: block;
    background: rgba(28, 64, 111, 0.1);
    color: #1c406f;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    padding: 0;
}

.pagination>li>a:hover {
    background: #cdedeb;
}

.pagination>li>a:active {
    background: #c3e7e4;
}

.pagination>li.selected>a,
.pagination>li.selected>a:hover {
    background: #26a69a;
    color: #fff;
    cursor: default;
}

.pagination>li.disabled,
.pagination>li.selected {
    pointer-events: none;
}

.pagination>li.disabled>a {
    color: rgba(28, 64, 111, 0.37);
    cursor: default;
}

.pagination>li:last-child {
    margin-right: 0;
}

.kompass-table.product-table .result-size {
    margin: 0 0 0 auto;
    color: rgb(28, 37, 58, 0.6);
}