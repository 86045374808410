.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.container > img {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  object-fit: cover;
}

.background {
  height: 100%;
  width: 100%;
  background: #374e59;
  border-radius: 4px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}