.tours {
  border-radius: 8px;
  border: solid 1px #eee;
}

.tour {
  display: flex;
  padding: 8px 8px 8px 12px;
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

.tour:nth-child(even) {
  background: #f9f9f9;
}

.tour:first-child {
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}

.tour:last-child {
  border-bottom: none;
  border-radius: 0 0 8px 8px;
}

.tour:hover,
tour:focus {
  background-color: #f3f7f6;
}

.tour:nth-child(even):hover,
.tour:nth-child(even):focus {
  background-color: #eff5f3;
}

.link :global(.mdi-icon) {
  color: #9ea5b5;
  width: 20px;
  height: 20px;
}

.number {
  width: 50px;
}

.title {
  flex: 1;
}

.todos {
  margin-top: 8px;
  padding-left: 24px !important;
  list-style-type: initial !important;
}

.todos>li {
  list-style-type: initial !important;
}

.warning {
  background: rgba(255, 165, 0, 0.19);
  color: rgb(207, 134, 0);
  padding: 12px;
  border-radius: 8px;
}

.problemsHeader {
  padding: 16px 16px 16px 0;
  font-weight: bold;
}

.venusButton {
  border-radius: 8px !important;
  width: auto !important;
  font-size: 12px;
  font-weight: bold;
  padding: 0 8px !important;
}