.container {
  width: 100%;
  background: #fff;
  padding: 0;
}

.container img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
}

.container.noGeoData {
  height: auto;
}

.container :global(.leaflet-container) {
  height: 100%;
}