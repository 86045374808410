

.product-selector-modal.modal .modal-content {
    padding: 0;
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    height: calc(100% - 66px);
    max-height: calc(100% - 66px);
    overflow: hidden;
    background: #fff;
}

.product-selector-modal.modal .modal-content > div {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    border-radius: 0;
}

.ReactModal__Content.product-selector-modal.modal .modal-footer {
    margin-bottom: 0;
    padding: 6px 16px 0px 16px;
    border-top: solid 1px #dadada5c;
    background: #1d406f05;
}

.product-selector-modal.modal {
    padding: 0;
    width: 100%;
    height: calc(100vh - 104px);
    top: 52px;
    bottom: 52px;
    max-height: initial;
}

@media screen and (min-width: 1024px) {
    .product-selector-modal.modal {
        width: 1024px;
    }
}

