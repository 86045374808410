

.card {
  background: #fff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.sectionBody {
  padding: 0;
}

.sectionHeader {
  padding: 0 16px 16px 0;
  font-weight: bold;
}

.element {
  display: flex;
  margin-bottom: 12px;
}

.elementLabel {
  display: flex;
  flex: 1;
  pointer-events: none;
}

.elementLabelContent {
  padding-right: 16px;
}

.elementLabelSeparator {
  border-bottom: dotted 1px #aaa;
  flex: 1;
  margin-bottom: 4px;
}

.elementValue {
  margin-left: 16px;
}

.elementValue.success {
  background: rgba(6, 190, 6, 0.21);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
  color: rgb(3, 121, 3);
}

.elementValue.warning {
  background: rgba(255, 165, 0, 0.19);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
  color: rgb(201, 131, 3)
}

.elementValue.warning :global(.mdi-icon) {
  color: orange;
}

.elementValue.success :global(.mdi-icon) {
  color: green;
}

.elementValue :global(.mdi-icon) {
  width: 16px;
  margin-right: 8px;
}

.checkYes {
  color: green;
}

.checkWarning {
  color: orange;
}

.daysInputContainer {
  position: relative;
  width: 170px;
}

.daysInputContainer :global(.input-field) {
  margin: 0;
}

.noInfo {
  font-weight: 500;
}

.daysInputLabel {
  position: absolute;
  right: 0;
  top: 12px;
  color: #aaa;
  user-select: none;
}

.daysUpdateContainer {
  display: flex;
}

.copyable {
  cursor: pointer;
  padding: 0 4px;
  margin: 0 -4px;
  border-radius: 4px;
  transition: background-color 0.1s;
}

.copyable:hover {
  background-color: #eee;
}

.tab {
  padding: 0 !important;
}

.body :global(.row) {
  margin: 0;
}

:global(input[type=text]).inputField {
  margin: 0;
  padding: 12px;
  height: auto;
  width: 155px;
  border: solid 2px #ddd;
  background: #fff;
  border-radius: 4px;
  transition: box-shadow .15s, border .15s;
}

:global(input[type=text]).inputField:hover {
  border-color: #aaa;
}

:global(input[type=text]).inputField:focus {
  border: solid 2px #26a69a !important;
  box-shadow: none !important;
}

.submitButton {
  border-radius: 4px;
  height: 46px;
  margin-left: 24px;
}

.proDaysInfo {
  font-size: 12px;
}

.closeButton {
  opacity: 1;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #b5bac3;
  cursor: pointer;
  transition: color 0.1s;
  user-select: none;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.closeButton :global(svg) {
  margin: 0 auto;
}

.closeButton:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.overlayTitle {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 16px;
}

.containerBackground {
  position: fixed;
  right: 0;
  width: 600px;
  background: #f3f4f5;
  height: calc(100% - 50px);
  top: 50px;
}


.platform {
  background: #333;
  padding: 0 6px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
}

.platform.stripe {
  background: #6155f6;
}

.platform.android {
  background: #A4C639;
}

.platform.et4, .platform.kompassapi {
  background: rgb(196, 206, 213);
  color: rgb(88, 110, 125);
}

.platform.ios {
  background: rgb(10,132,255);
}

.platform.web {
  background: var(--kom-c-secondary-500);
}

.platform.crm {
  background: var(--kom-c-accent-500);
}

.raw {
  background: #f7f7f7;
  color: #6a7174;
  font-size: 8px;
  margin-top: 12px;
  font-family: monospace;
  padding: 4px;
}

.days {
  color: green;
  display: flex;
  align-items: center;
}

.days :global(.mdi-icon) {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}

.days span {
  color: #888;
}

.endDate {

}

.top {
  display: flex;
  margin-bottom: 8px;
  line-height: 21px;
  align-items: center;
}

.created {
  flex: 1;
}