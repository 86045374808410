.modal-title {
    margin: 0;
    padding: 0 0 24px;
    font-size: 1.5rem;
    color: #1d406f;
    font-weight: 500;
}

.modal-title small {
    font-size: 55%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}