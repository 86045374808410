.loadingContainer {
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.loadingContainerInner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loadingMessage {
  margin-top: 16px;
  font-size: 18px;
  opacity: 0.5;
}