.container {
  align-items: left;
  padding-top: 164px;
  position: fixed;
  left: 0;
  top: 0;
  width: 320px;
  height: 100vh;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
  border-right: solid 1px #eee;
  overflow-y: auto;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
}

.filterElement {
  background: #fff;
  border-radius: 4px;
  padding: 12px 12px 12px 0;
  margin-right: 12px;
}

.filterElement :global([type="checkbox"]+span:not(.lever)) {
  height: 20px;
  line-height: 20px;
}

.filterElement :global([type="checkbox"]+span) {
  color: #374e59;
}

.filterElement :global([type="checkbox"].filled-in:checked+span) {
  font-weight: 500;
}
