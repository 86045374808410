
.element {
  display: flex;
  margin-bottom: 12px;
}

.label {
  display: flex;
  flex: 1;
  pointer-events: none;
}

.content {
  padding-right: 16px;
}

.separator {
  border-bottom: dotted 1px #aaa;
  flex: 1;
  margin-bottom: 4px;
}

.value {
  margin-left: 16px;
}

.value.fixedWidth {
  width: 68px;
  padding-right: 8px !important;
}

.value.validation {
  padding-right: 8px !important;
  font-weight: 500;
}



.value.fixedWidth > div {
  display: flex;
}

.value.fixedWidth > div > span {
  flex: 1;
  text-align: right;
  font-weight: 500;
}

.value.success {
  background: rgba(6, 190, 6, 0.21);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
  color: rgb(3, 121, 3);
}

.value.warning {
  background: rgba(255, 165, 0, 0.19);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
  color: rgb(201, 131, 3)
}

.value.inactive {
  color: rgb(108, 112, 116);  
  background: rgb(224, 229, 235);
  border-radius: 8px;
  padding: 0 4px;
  margin-right: -4px;
}
.value.highlight {
  color: #fff;
  background: color(srgb 0.1112 0.2583 0.4327);
  border-radius: 8px;
  padding: 0 8px 0 4px;
  margin-right: -4px;
}

.value.warning :global(.mdi-icon) {
  color: orange;
}

.value.success :global(.mdi-icon) {
  color: green;
}

:global(.mdi-icon.success) {
  color: green;
}

:global(.mdi-icon.warning) {
  color: orange;
}

.value :global(.mdi-icon) {
  width: 16px;
  margin-right: 8px;
}

.value :global(.external-link) {
  color: rgb(28, 37, 58);
  text-decoration: none;
  cursor: pointer;
  padding: 0 4px;
  margin: 0 -4px;
  border-radius: 4px;
  transition: background-color 0.1s;
}

.value :global(.external-link:hover) {
  background-color: #eee;
}

.copyable {
  cursor: pointer;
  padding: 0 4px;
  margin: 0 -4px;
  border-radius: 4px;
  transition: background-color 0.1s;
}

.copyable:hover {
  background-color: #eee;
}

.action {
  display: flex;
}

.action button {
  background: #3d485c;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 0px;
  text-align: center;
  color: #fff;
  margin-left: 12px;
  cursor: pointer;
  padding: 0;
  border: none;
  box-shadow: none;
}

.action button:hover {
  background: #49556b;
}

.action button:active {
  background: #384355;
}

.action button :global(.mdi-icon) {
  margin: 0 auto;
  width: 16px;
  height: 16px;
}