.container {
  display: flex;
  padding: 0px 24px 0 24px;
  height: 95px;
  min-height: 95px;
  border-bottom: solid 1px #ddd;
  position: sticky;
  top: 64px;
  background: #fff;
  z-index: 1;
  align-items: center;
}

.productName {
  font-weight: bold;
  font-size: 18px;
  color: #1d406f;
  flex: 1;
}

.isbn > span {
  margin-right: 4px;
}


.editButton {
  border-radius: 4px;
  border: none;
  height: auto;
  width: auto;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  user-select: none;
  background-color: #fff;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
  color: #7d8897;
  height: 32px;
  padding: 0px 8px 0px 6px;
  transition: background-color 0.15s, border 0.15s, box-shadow 0.15s;
}

.editButton:hover,
.editButton:focus {
  background-color: #f3f8ff;
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
}

.editButton:active {
  background-color: #edf2fb;
  border: solid 1px #1c406f;
  box-shadow: 0 1px 4px #00000017;
}

.editButton>.pencilIcon {
  width: 14px;
  height: 14px;
  margin-top: 0px;
  margin: 0 auto;
}

.editButton > div {
  padding-left: 6px;
  font-size: 14px;
}