.selection {
  background: #374e59;
  border-radius: 8px;
  padding: 12px 16px;
  position: relative;
}

.icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #51a151;
  margin-right: 8px;
  color: #fff;
  display: flex;
  align-items: center;
}

.icon.success {
  background: #51a151;
  color: #fff;
}

.icon.warning {
  background: orange;
  color: #fff;
}

.icon.iconFetching {
  background: #ffffff17;
}

.icon :global(.mdi-icon) {
  width: 14px;
  height: 14px;
  margin: 0 auto;
}


.selectionLabel {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}

.selectionValueContainer {
  display: flex;
  align-items: center;
}

.selectionValue {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 18px;
}

.selectionValue.valueFetching {
  height: 18px;
  background: #ffffff17;
  width: 120px;
}