.container {
  background: #fff;
  display: flex;
  margin-bottom: 16px;
  box-shadow: 0 6px 16px rgb(0 0 0 / 3%);
  border-radius: 8px;
  cursor: pointer;
}

.container:hover {
  background-color: #f3f7f6;
  box-shadow: 0 6px 24px rgb(0 0 0 / 8%);
}