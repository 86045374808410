.dropdownButton {
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-right: 12px;
  border: none;
  height: 42px;
  width: auto;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  user-select: none;
  margin-bottom: 12px;
  border: solid 1px #d6d5d5;
  box-shadow: 0 1px 3px #00000012;
}

.label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  color: rgb(108, 112, 116);
}

.dropdownButton.kompass {
  width: 100%;
}

.dropdownButton > div:first-child {
  flex: 1;
}

.dropdownButton > svg {
  width: 20px;
  height: 20px;
  color: #27a69a;
  margin-top: 2px;
}

.dropdownButton .filterIcon {
  color: rgb(108, 112, 116);
  opacity: 0.4;
}

.dropdownButton > .chevron {
  width: 18px;
  height: 18px;
  color: #666;
  margin-top: 0px;
}

.itemEmptyLabel {
  flex: 1;
  opacity: 0.4;
  padding-left: 8px;
}

.itemLabel {
  font-weight: 500;
  flex: 1;
}

.dropdownButton.open > .chevron {
  transform: rotate(180deg);
}

.dropdownButton:focus,
.dropdownButton:hover,
.dropdownButton.open  {
  border: solid 1px #c3c1c1;
  box-shadow: 0 1px 4px #00000017;
  background: #fff;
}

.dropdownButton.open {
  border: solid 1px #26a69a;
  background: #fff;
  box-shadow: 0 1px 4px #00000036;
}


.dropdownMenu {
  background: #fff;
  box-shadow: 0 -1px 3px rgb(0 0 0 / 5%), 0 26px 32px rgb(0 0 0 / 10%);
  position: absolute;
  left: 0;
  top: 76px;
  width: 100%;
  min-width: 100%;
  border-radius: 8px;
  padding: 4px;
  height: auto;
  overflow: auto;
  z-index: 1;
}

.dropdownMenu.kompass {
  width: 100%;
  min-width: 100%;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  cursor: pointer;
  user-select: none;
  color: #1c406f;
  border-radius: 4px;
}

.dropdownItem.allItems {
  position: relative;
  margin-bottom: 8px;
}

.dropdownItem.allItems:before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: -4px;
  right: -4px;
  height: 0px;
  border-bottom: dashed 1px #ddd;
}

.dropdownItem.unset {
  position: relative;
  margin-top: 8px;
}

.dropdownItem.unset:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  height: 0px;
  border-top: dashed 1px #ddd;
}

.dropdownItem:hover {
  background: #cdedeb;
}

.dropdownItemName {
  flex: 1;
}

.dropdownItem svg {
  width: 18px;
  height: 18px;
}

.dropdownItemLabel {
  flex: 1;
  padding-left: 8px;
}