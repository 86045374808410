.section {
  padding: 0 0 24px 0;
}

.sectionTitle {
  font-weight: bold;
}

.sectionInfo {
  padding: 8px 0;
  font-size: 12px;
}

:global(.modal-footer) {
  padding-right: 24px;
  background: #fff !important;
}

.appRadio label {
  display: block;
}

.subTitle {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal {
  border-radius: 12px;
  width: 800px !important;
  max-height: auto !important;
  background: #f3f4f5 !important;
}

.modalSubTitle {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgb(158, 158, 158);
}

.modalHeader {
  background: #fff;
  border-radius: 12px 12px 0 0;
  font-size: 20px;
  padding: 24px;
  font-weight: 500;
  border-bottom: solid 1px #dedede;
}

.modalFooter {
  background: #fff;
  margin: 0;
  padding: 24px;
  height: auto;
  border-top: solid 1px #dedede;
  border-radius: 0 0 12px 12px;

}

.selectionContainer {
  flex: 1;
}

.selection {
  background: #000;
  border-radius: 8px;
  padding: 8px;
}

.selectionLabel {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
}

.selectionValueContainer {
  display: flex;
  align-items: center;
}

.selectionIcon {}

.selectionValue {
  font-size: 16px;
  color: #fff;
}

.inputContainer {}

.inputWrapper {
  background: #fff;
  box-shadow: 0 -1px 8px #0000000f;
  border-radius: 8px;
  margin-top: 8px;
}

.list {
  height: 250px;
  max-height: 250px;
  border-radius: 0 0 8px 8px;
  background: #fff;
}

.listItem {
  padding: 8px 12px;
  border-bottom: solid 1px #ddd;
}

.rows {
  display: flex;
}

.rows>div {
  flex: 1;
}

.rows>div:first-child {
  padding-right: 8px;
}

.rows>div:last-child {
  padding-left: 8px;
}

.arrowDownIcon {
  margin: 8px auto;
  color: #bfc6c9;
}

@media screen and (max-height: 1060px) {
  .modal {
    max-height: 90% !important;
    top: 5% !important;
  }
}
