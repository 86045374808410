.page-title {
    background: #fff;
    box-shadow: 0 0 2px rgba(0,0,0,.2),0 0 8px rgba(0,0,0,.1);
    margin-bottom: 24px;
    position: sticky;
    top: 64px;
    z-index: 1;
}

@media screen and (max-width: 992px) {
    .page-title {
        top: 56px;
    }
}

.page-title .inner {
    margin: 0 auto;
    padding: 18px 24px;
}

.page-title .inner h5 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    color: #1d406f;
    font-weight: 500;
}

.page-title.with-back-button .inner {
    display: flex;
}

.page-title.with-search .inner {
    display: flex;
    align-items: center;
}

.page-title.with-search .inner h5 {
    flex: 1;
}

.page-title.with-back-button .inner .back-button {
    width: 24px;
    height: 24px;
    margin-left: -40px;
    margin-right: 16px;
    padding-top: 3px;
    color: #008275;
    cursor: pointer;
}

.page-title.with-breadcrumbs .inner {
    padding: 32px 24px 26px 24px;
}

.page-title.with-back-button .inner .back-button .mdi-icon {
    width: 22px;
    height: 22px;
}

.page-title .breadcrumbs-container {
    margin-top: -14px;
}

.page-title .breadcrumbs {
    margin-bottom: 4px;
}

.page-title .breadcrumb-link {
    color: #008275;
    font-weight: 500;
    position: relative;
    margin-right: 12px;
    margin-left: 6px;
    user-select: none;
}

.page-title .breadcrumb-link:first-child {
    margin-left: 0;
}

.page-title .breadcrumb-link:last-child {
    margin-right: 0;
}

.page-title .breadcrumb-link:hover {
    border-bottom: solid 2px #008275;
}

.page-title .breadcrumb-link[disabled] {
    color: #1d406f;
    pointer-events: none;
}


.page-title .breadcrumb-link:after {
    content: ' / ';
    color: #1d406f;
    position: absolute;
    right: -12px;
}

.page-title .breadcrumb-link:last-child:after {
    content: none;
}


@media screen and (max-width: 1170px) {
    .page-title .inner {
        width: 100%;
        margin: 0;
    }
}